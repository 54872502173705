<template>
    <div class="bg-blue-50 py-6">
        <div class="container mx-auto px-4">
        <!-- Título de la sección -->
            <!-- <h2 class="text-3xl font-bold text-center mb-8">  Capacitaciones</h2> -->
            <!-- Contenedor de las tarjetas -->
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <!-- Tarjeta de capacitación -->
                <div
                v-for="(capacitacion, index) in posts"
                :key="index"
                class="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105 border-b-4 border-blue-400 "
                >
                    <img
                        @click="getPostA(capacitacion.id)"
                        v-if="capacitacion.post_image.length > 0"
                        :src="'https://api-dev-copef.vicomser.com/storage/' + capacitacion.post_image[0].url + capacitacion.post_image[0].image.name"
                        :alt="capacitacion.post_image[0].image.name"
                        class="w-full h-48 object-cover cursor-pointer"
                        >
                    <div class="p-6">
                        <h3 @click="getPostA(capacitacion.id)" class="text-xl font-bold mb-3 text-gray-800 cursor-pointer">{{ capacitacion.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'CardConvetion',
    data() {
        return {
            posts: []
        }
    },
    created(){
        this.getEducation()
    },
    methods:{
        getEducation(postId = null){
            let postIdDetail
            if (postId){
                postIdDetail  = postId
            } else {
                postIdDetail = this.$route.params.id;
            }
            this.$http.get('/posts-complete?post_category_id=12')
                .then( resp => {
                        const onlyforpost= resp.data.data.data
                        const noticiasFiltradas = onlyforpost.filter((post,i)=>i <= 3);
                        if (postIdDetail) {
                            this.posts = noticiasFiltradas.filter(noticia => String(noticia.id) !== String(postIdDetail));
                            if(this.posts.length > 3 ){
                                this.posts= this.posts.filter((post,i)=>i <= 2)
                            }
                            } else {
                                this.posts = noticiasFiltradas;
                            }
                        })
                        .catch(error => {
                        console.error('Error al realizar la solicitud :', error);
                    });
        },
        // getPostA(postId){
        //     this.$emit("newGetPost" ,postId)
        //     this.$router.push({ name: 'education-detail', params: { id: postId }})
        //     this.getEducation(postId)
        // },
        getPostA(postId){
            this.$router.push({ name: 'convention-detail', params: { id: postId }}).then(() => {
            this.$router.go(); // Recargar la página actual después de navegar a la nueva ruta
            });
        },

    }
    }
</script>

<style>

</style>
