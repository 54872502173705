<template>
    <Convention />
</template>

<script>
import Convention from '@/components/content/convention/Convention.vue'

export default {
    name:'ConventionView',
    components:{Convention}
}
</script>