<template>
  <div class="bg-white  max-w-6xl mx-auto py-6">
    <div class="container px-4 sm:px-8 flex flex-col">
      <h2 class="text-3xl font-bold text-gray-500 mb-2">Convenios</h2>
      <p class="text-base lg:text-lg text-gray-500 font-medium leading-relaxed ">Descubre nuestros últimos convenios realizados.</p>
    </div>
  </div>

  <div class="p-2 bg-gray-100 shadow-lg rounded-lg">
    <div class="max-w-6xl mx-auto">
      <div v-for="(capacitacion, index) in sortedPosts " :key="index" class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 m-4 relative">
        <div class="relative p-4">
          <!-- Ícono del Corazón -->
          <div class="absolute top-0 right-0 bg-white rounded-lg p-2 ">
            <i class="far fa-heart text-blue-500"></i>
          </div>
          <div class="flex flex-col lg:flex-row items-center lg:justify-between">
            <div class="w-full lg:w-1/4 mb-4 lg:mb-0 lg:pr-4">
              <img v-if="capacitacion.post_image.length > 0"
                  :src="'https://api-dev-copef.vicomser.com/storage/' + capacitacion.post_image[0].url + capacitacion.post_image[0].image.name"
                  :alt="capacitacion.post_image[0].image.name"
                  class="w-full h-auto rounded-lg shadow-md"
              >
            </div>
            <div class="w-full lg:w-3/4 mb-4 ">
              <h3 @click="getPost(capacitacion.id)" class="text-lg font-semibold text-gray-700 mb-2 cursor-pointer hover:text-blue-500">{{ capacitacion.title }}</h3>
              <div
                  class="text-base lg:text-lg text-gray-700 font-light leading-relaxed"
                  v-html="shortenedHtml(capacitacion.content)">
                </div>
            </div>
          </div>
          <div class="flex justify-between items-center mt-2">
            <p class="text-xs text-gray-400">Publicado el {{ formatDate(capacitacion.created_at) }}</p>
            <span @click="getPost(capacitacion.id)" class="block text-blue-400 transition duration-300 cursor-pointer hover:text-blue-500">Ver más</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      posts: [],
    }
  },
  computed: {
    sortedPosts() {
    return this.posts.slice().sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA;
    });
    },
    shortenedHtml() {
      return (html) => this.getShortenedHtml(html, 35, 40);
    }
  },
  created(){
    this.getEducation()
  },
  methods: {
    getShortenedHtml(html, minWords, maxWords) {
      // Crear un elemento temporal para manipular el HTML
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;

      // Extraer el texto sin etiquetas HTML
      const textContent = tempDiv.textContent || tempDiv.innerText || "";

      // Dividir el texto en palabras
      const words = textContent.split(/\s+/);

      // Seleccionar entre 35 y 40 palabras
      const wordCount = Math.min(maxWords, Math.max(minWords, words.length));
      let selectedWords = words.slice(0, wordCount);

      // Unir las palabras seleccionadas en un string
      let resultText = selectedWords.join(' ');

      // Añadir la palabra completa si está cortada
      if (words.length > wordCount) {
        resultText += words[wordCount] ? ` ${words[wordCount]}...` : '...';
      } else {
        resultText += words.length > maxWords ? '...' : '';
      }

      // Retornar el texto procesado como HTML
      return resultText;
    },
    getPost(postId){
      this.$router.push({name:'convention-detail', params:{id:postId}})
    },
    getEducation(){
      this.$http.get('/posts-complete-to-web?post_category_id=12')
      .then(resp=>{
          this.posts = resp.data.data.data;

      })
      .catch(error => {
          console.error('Error al realizar la solicitud :', error);
      });
    },
    formatDate(StringDate){
              const date  =  new Date (StringDate)
              return date.toLocaleString()
        },
  }
}
</script>
